var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manager-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": _vm.tabPosition },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Location Setting", name: "location" } },
            [_c("location-list")],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Product Setting", name: "product" } },
            [_c("product-list")],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Fuel Type Setting", name: "fuelType" } },
            [_c("fuel-type-code-list")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }