<template>
  <div class="manager-container">
    <el-tabs v-model="activeName" :tab-position="tabPosition">
      <el-tab-pane label="Location Setting" name="location"><location-list> </location-list></el-tab-pane>
      <el-tab-pane label="Product Setting" name="product"><product-list/></el-tab-pane>
      <el-tab-pane label="Fuel Type Setting" name="fuelType"><fuel-type-code-list/></el-tab-pane>
      <!-- <el-tab-pane label="GST" name="gst">GST</el-tab-pane> -->
      <!-- <el-tab-pane label="Payment Type" name="paymentType">Payment Type</el-tab-pane> -->
      <!-- <el-tab-pane label="Other Cost" name="otherCost">Other Cost</el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'
import FuelTypeCodeList from './components/FuelTypeCodeList.vue'
import LocationList from './components/LocationList.vue'
import ProductList from './components/product/ProductList.vue'

export default {
  name: 'SettingManager',
  components: { LocationList, ProductList,FuelTypeCodeList },
  mixins: [baseMixin, baseTableMixin],
  data () {
    return {
      tabPosition: 'left',
      activeName: 'location'
    }
  },

  computed: {
    ...mapState(['permissions,locationList']),
    hasSysAdminPermission () {
      return this.permissions.hasSysAdminPermission
    }
  },

  created () {
  },

  methods: {
  },

  watch: {
    'currentCompany.id': {
      handler (value) {
        if (value) {
          this.getRolesByCompany(value)
        }
      },
      immediate: true
    },
    '$route' (to) {
      const { companyId } = to.params
      this.currentCompany = this.companyList.find(item => item.id === companyId) || {}
    },
    activeName(curValue,prevValue) {
      if (curValue) {

        switch (curValue) {
          case 'location':
          this.$store.dispatch('getLocationList')
            break;
          case 'product':
          this.$store.dispatch('getProductList')
            break;
          case 'fuelType':
          this.$store.dispatch("getMpaFuelTypeList");
            break;

          default:
            break;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-tabs__content{
  height: 100%;
  width: 80%;
  margin: 0 auto;
  overflow-y: scroll;
}
/deep/ .el-table__expanded-cell{
  padding-left: 5%;
  padding-right: 5%;
  .el-table .el-table__cell:last-child{
    border-right: none;
  }
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover>td{
background-color: #F5F7FA !important;
color:#0D7BFF !important;
}

</style>
